var render = function render(){var _vm=this,_c=_vm._self._c;return _c('editor',{attrs:{"api-key":_vm.tinyMCE,"init":{
    plugins: 'code table media link fullscreen',
    image_title: false,
    image_dimensions: false,
    image_description: false,
    relative_urls: true,
    remove_script_host: false,
    convert_urls: true,
    toolbar: this.toolbar,
    fontsize_formats: '11px 12px 14px 16px 18px 24px 32px 36px 48px',
    menubar: this.showMenuBar,
  }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})
}
var staticRenderFns = []

export { render, staticRenderFns }