<template>
  <editor
    v-model="text"
    :api-key="tinyMCE"
    :init="{
      plugins: 'code table media link fullscreen',
      image_title: false,
      image_dimensions: false,
      image_description: false,
      relative_urls: true,
      remove_script_host: false,
      convert_urls: true,
      toolbar: this.toolbar,
      fontsize_formats: '11px 12px 14px 16px 18px 24px 32px 36px 48px',
      menubar: this.showMenuBar,
    }"
  />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  props: {
    value: {},
    basic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  computed: {
    showMenuBar() {
      if (this.basic) {
        return false;
      }

      return true;
    },
    toolbar() {
      if (this.basic) {
        return "undo redo | bold italic";
      }

      return "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect | image | fullscreen";
    },
  },
  data() {
    return {
      text: this.value,
      tinyMCE: process.env.VUE_APP_TINY_MCE,
    };
  },
  methods: {
    update() {
      this.text = this.value;
    },
  },
  watch: {
    text(value) {
      this.$emit("input", value);
    },
  },
};
</script>
